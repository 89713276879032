define("discourse/plugins/discourse-topic-voting/discourse/templates/connectors/topic-above-post-stream/topic-title-voting", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.can_vote}}
    {{#if this.model.postStream.loaded}}
      {{#if this.model.postStream.firstPostPresent}}
        <div class="voting title-voting">
          {{! template-lint-disable no-capital-arguments }}
          <MountWidget
            @widget="vote-box"
            @args={{this.model}}
            @showLogin={{route-action "showLogin"}}
          />
        </div>
      {{/if}}
    {{/if}}
  {{/if}}
  */
  {
    "id": "w4HEz4wQ",
    "block": "[[[41,[30,0,[\"model\",\"can_vote\"]],[[[41,[30,0,[\"model\",\"postStream\",\"loaded\"]],[[[41,[30,0,[\"model\",\"postStream\",\"firstPostPresent\"]],[[[1,\"      \"],[10,0],[14,0,\"voting title-voting\"],[12],[1,\"\\n\"],[1,\"        \"],[8,[39,1],null,[[\"@widget\",\"@args\",\"@showLogin\"],[\"vote-box\",[30,0,[\"model\"]],[28,[37,2],[\"showLogin\"],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[]],null]],[],false,[\"if\",\"mount-widget\",\"route-action\"]]",
    "moduleName": "discourse/plugins/discourse-topic-voting/discourse/templates/connectors/topic-above-post-stream/topic-title-voting.hbs",
    "isStrictMode": false
  });
});